import { Add, Remove } from "@mui/icons-material"
import { Box, Button, IconButton } from "@mui/material"
import { ComponentType, PropsWithChildren } from "react"
import { ProductDetailViewType } from "../app/ProductDetail"

const blankImage = require('./images/blank400300.png')

const ProductDetailView: ProductDetailViewType = ({ product: item, numOrder, disableDecrementButton, disableOrderButton, onDecrementNumOrder, onIncrementNumOrder, onSend: onOrder, isSendComplete, onClearIsSendComplete, isReserve }) => {
    const renderImage = () => {
        if (item.image == null) {
            return (
                <Box
                    component="img"
                    className="menu_img"
                    src={blankImage}
                />
            )
        }
        return (
            <Box
                component="img"
                className="menu_img"
                src={blankImage}
                sx={{
                    background: `url(${item.image.url}) center center no-repeat`,
                    backgroundSize: 'cover',
                }}
            />
        )
    }
    const renderBottom = () => {
        if (isReserve) {
            if (!isSendComplete) {
                return (
                    <Row>
                        {numOrder} <IconButton onClick={onIncrementNumOrder}><Add /></IconButton> <IconButton onClick={onDecrementNumOrder} disabled={disableDecrementButton}><Remove /></IconButton>
                        <Button variant="contained" onClick={() => onOrder()} disabled={disableOrderButton}>カートに追加する</Button>
                    </Row>
    
                )
            } else {
                return (
                    <>
                        <Row>
                            <p>カートに追加しました！</p>
                        </Row>
                        <Row>
                            <Button onClick={() => onClearIsSendComplete()}>戻る</Button>
                        </Row>
                    </>
                )
            }
        } else {
            if (!isSendComplete) {
                return (
                    <Row>
                        {numOrder} <IconButton onClick={onIncrementNumOrder}><Add /></IconButton> <IconButton onClick={onDecrementNumOrder} disabled={disableDecrementButton}><Remove /></IconButton>
                        <Button variant="contained" onClick={() => onOrder()} disabled={disableOrderButton}>注文する</Button>
                    </Row>
    
                )
            } else {
                return (
                    <>
                        <Row>
                            <p>ご注文を承りました！</p>
                        </Row>
                        <Row>
                            <Button onClick={() => onClearIsSendComplete()}>戻る</Button>
                        </Row>
                    </>
                )
            }
        }

    }
    return (
        <section className="menu_single ">
            <div className="menu_block">
                <h2>{item.label}</h2>

                {renderImage()}
                <span className="price">{item.price}円(税込)</span>
                <div className="text">
                    {item.description}
                </div>
            </div>
            {renderBottom()}
        </section>
    )
}

export default ProductDetailView

const Row: ComponentType<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <Box sx={{ flex: 1, padding: 2, overflowWrap: 'break-word' }}>
            {children}
        </Box>
    )
}